export const serviceIdMap = new Map();

export const ServiceIds = {
    daily: "vc-fanclash-quizinc-cm-f-daily-01",
    weekly: "vc-fanclash-quizinc-cm-f-weekly-01",
    monthly: "vc-fanclash-quizinc-cm-f-monthly-01",
    dailyFusion: "vc-fanclash-quizinc-cm-f-daily-02",
    weeklyFusion: "vc-fanclash-quizinc-cm-f-weekly-02",
    monthlyFusion: "vc-fanclash-quizinc-cm-f-monthly-02",
    dailyApige: "vc-fanclash-quizinc-cm-a-daily-02",
    weeklyApige: "vc-fanclash-quizinc-cm-a-weekly-02",
    monthlyApige: "vc-fanclash-quizinc-cm-a-monthly-02"
}

serviceIdMap.set(ServiceIds.free, 1);
serviceIdMap.set(ServiceIds.daily, 2);
serviceIdMap.set(ServiceIds.weekly, 3);
serviceIdMap.set(ServiceIds.monthly, 4);
serviceIdMap.set(ServiceIds.dailyFusion, 2);
serviceIdMap.set(ServiceIds.weeklyFusion, 3);
serviceIdMap.set(ServiceIds.monthlyFusion, 4);
serviceIdMap.set(ServiceIds.dailyApige, 2);
serviceIdMap.set(ServiceIds.weeklyApige, 3);
serviceIdMap.set(ServiceIds.monthlyApige, 4);
